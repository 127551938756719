import React from 'react'

function dienst({naam, tekst, classnaam}) {
    return (
      <div className={`dienst ${classnaam}`}>
        <div className='container'>
          <h2>{naam}</h2>
          <p>{tekst}</p>
        </div>
      </div>
    )
}

export default dienst