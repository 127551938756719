import React from 'react'
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import Dienst from '../components/dienst'
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

function diensten() {
  return ( 
    <Layout>
        <Helmet>
          <title>Diensten - Website Laten Stylen</title>
          <meta name="description" content="Ik style jouw website of webapplicatie met alle plezier of ontwikkel een template op basis van jouw ontwerp." />
          <meta property="og:description" content="Ik style jouw website of webapplicatie met alle plezier of ontwikkel een template op basis van jouw ontwerp." />
        </Helmet>
        <div className='container'>
          <h1>Diensten</h1>
          <p>Als Wordpress developer bouw ik geregeld Wordpress websites, themes en plugins. Op de oude manier (coden) of met pagebuilders als Elementor of Bricks. Maar daarnaast heb ik een passie voor CSS en styling. Gezien mijn 14 jaar ervaring als webdeveloper kan ik je helpen op verschillende vlakken, die ik hieronder voor je uiteen zet.</p>
        </div>
        <Dienst naam="Website stylen" tekst="Ik style jouw website met alle plezier, ongeacht het CMS. Ik heb jarenlange ervaring met vrijwel alle grote Open Source CMS systemen en webshops zoals Wordpress, Drupal, TYPO3, WooCommerce, Magento, Prestashop & Open Cart. Een maatwerk website kan natuurlijk ook!" classnaam="oneven" />
        <Dienst naam="Webapplicatie stylen" tekst="Ik heb ervaring met PHP frameworks zoals bijvoorbeeld Laravel en CakePHP. Maar daarnaast werk ik ook graag met Javascript frameworks zoals React. Hierdoor kan ik dus ook moeiteloos de styling doen van uw webapplicatie. Terwijl de die-hard backend programmeurs alle functionaliteit bouwen zorg ik dat het er mooi uit ziet!" classnaam="even" />
        <Dienst naam="Templates ontwikkelen" tekst="Geef me een ontwerp in welk formaat dan ook (Photoshop bestand, Adobe XD of Figma design) en ik bouw het om naar HTML/CSS, een Wordpress theme of een template voor een ander Open Source Content Management Systeem zoals Drupal, TYPO3, WooCommerce, Prestashop of Open Cart." classnaam="oneven" />
        <div className="even">
          <div className='container'>
            <h2>Snelle websites</h2>
            <p>Wil jij ook zo'n snelle site als deze? <Link to="/ik-wil-een-snelle-website/">Lees dan snel verder!</Link></p>
            <div data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" className='fastsite'><StaticImage src={`../images/fast.png`} alt='Ik wil een snelle website' layout='fixed' width={150} height={150} /></div>
          </div>
      </div>
    </Layout>
  )
}

export default diensten